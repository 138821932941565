<template>
  <VContainer
    fluid
  >
    <VRow>
      <VCol>
        <h1
          class="tt-text-headline-1"
          data-test="all-reports"
        >
          Отчеты
        </h1>
      </VCol>
    </VRow>
    <VRow>
      <VCol
        cols="3"
      >
        <div
          class="black--text"
          data-test="start-date"
        >
          Начало
        </div>
        <SDatePicker
          :value="entity.start_at"
          format="DD.MM.YYYY"
          single-line
          hide-details="auto"
          data-test="choose-start-date"
          @input="v => handleChange('start_at', v)"
        />
      </VCol>
      <VCol cols="3">
        <div
          class="black--text"
          data-test="end-date"
        >
          Окончание
        </div>
        <SDatePicker
          :value="entity.end_at"
          format="DD.MM.YYYY"
          single-line
          hide-details="auto"
          data-test="choose-end-date"
          @input="v => handleChange('end_at', v)"
        />
      </VCol>
    </VRow>
    <VRow
      class="align-center justify-space-between"
    >
      <VCol
        cols="6"
      >
        <div class="d-flex align-center">
          <TTBtn
            depressed
            large
            class="mr-5"
            data-test="generate-report"
            @click="generateReport"
          >
            Сформировать отчет
          </TTBtn>
          <TTSelect
            ref="locationDropDown"
            large
            placeholder="Выбор локации"
            :value="currentLocation"
            :items="locations"
            item-text="name"
            item-value="id"
            return-object
            data-test="select-location"
            @change="handleLocation"
          >
            <template #selection="{item,disabled}">
              <TextTemplate
                class="v-select__selection v-select__selection--comma"
                :disabled="disabled"
                :text="item.name"
                :show-prefix="!item.is_active && !!item.id"
              />
            </template>
            <template #item="{item}">
              <TextTemplate
                :text="item.name"
                :show-prefix="!item.is_active && !!item.id"
              />
            </template>
          </TTSelect>
        </div>
      </VCol>
    </VRow>
    <VRow v-if="currentLocation">
      <VCol>
        <VTabs
          v-model="currentTab"
          grow
          slider-color="white"
          color="#000"
          active-class="active-tab-background"
          class="mb-4"
        >
          <VTabsSlider color="#000" />
          <VTab
            v-for="item in tabNames"
            :key="item"
            active-class="grey lighten-2"
            class="grey lighten-4"
          >
            {{ item }}
          </VTab>
        </VTabs>
        <VTabsItems v-model="currentTab">
          <VTabItem>
            <ReportStaff :staffs="reportsStaff" />
          </VTabItem>
          <VTabItem>
            <ReportEvents :events="reportsEvent" />
          </VTabItem>
          <VTabItem>
            <ReportProgress />
          </VTabItem>
        </VTabsItems>
      </VCol>
    </VRow>
    <VRow
      v-else
    >
      <VCol cols="12">
        <h1 class="mb-3 tt-text-headline-1">
          Не задана локация
        </h1>
        <p class="mb-0">
          Выберите локацию для получения данных.
        </p>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
/* eslint-disable camelcase */

import { pageLocationToken } from '@/services';

import { formatDate } from '@/utils';

import SDatePicker from '@/components/ui/SDatePicker.vue';
import ReportEvents from '@/components/reports/ReportEvents.vue';
import ReportStaff from '@/components/reports/ReportStaff.vue';
import ReportProgress from '@/components/reports/ReportProgress.vue';
import TextTemplate from '@/components/shared/TextTemplate.vue';

export default {
  name: 'Reports',
  components: {
    TextTemplate,
    ReportProgress,
    ReportStaff,
    ReportEvents,
    SDatePicker,
  },
  data() {
    return {
      currentTab: '',
      tabNames: [
        'По сотрудникам', 'По событиям', 'По прогрессу',
      ],
      reportNames: [
        'reportsStaff', 'reportsEvent', 'reportsProgress',
      ],
      apiCallNames: ['getReportStaff', 'getReportEvent', 'getReportProgress'],
      locations: [],
      currentLocation: '',
      reportsStaff: {},
      reportsEvent: {},
      reportsProgress: {},
      entity: {
        start_at: '',
        end_at: '',
      },
    };
  },
  computed: {
    formattedStart() {
      const { start_at } = this.entity;
      return this.$dayjs(start_at, 'DD-MM-YYYY')
        .format('YYYY-MM-DD');
    },
    formattedEnd() {
      const { end_at } = this.entity;
      return this.$dayjs(end_at, 'DD-MM-YYYY')
        .format('YYYY-MM-DD');
    },
  },
  created() {
    this.entity.start_at = this.$dayjs()
      .subtract(10, 'day')
      .format('DD.MM.YYYY');
    this.entity.end_at = this.$dayjs()
      .format('DD.MM.YYYY');
    const loc = pageLocationToken.get();
    if (loc) {
      this.currentLocation = parseInt(loc, 10);
      //
      // snamiApi.getReportStaff({
      //   location_id: loc,
      //   start_at: formattedStartAt,
      //   end_at: formattedEndAt,
      // }).then((r) => {
      //   const { data } = r.data;
      //   this.reportsStaff = data;
      // }).catch(e => console.warn(e));
      //
      // snamiApi.getReportEvent({
      //   location_id: loc,
      //   start_at: formattedStartAt,
      //   end_at: formattedEndAt,
      // }).then((r) => {
      //   const { data } = r.data;
      //   this.reportsEvent = data;
      // }).catch(e => console.warn(e));
      //
      // snamiApi.getReportProgress({
      //   location_id: loc,
      //   start_at: formattedStartAt,
      //   end_at: formattedEndAt,
      // }).then((r) => {
      //   const { data } = r.data;
      //   this.reportsProgress = data;
      // }).catch(e => console.warn(e));
    }
    this.$repositories.location.list({})
      .then((r) => {
        const { data } = r.data;
        this.locations = data;
      })
      .catch((e) => console.warn(e));
  },
  mounted() {
    if (!this.currentLocation) {
      this.$refs.locationDropDown.isMenuActive = true;
    }
  },
  methods: {
    formatDate,
    handleChange(name, value) {
      this.entity[name] = value;
    },

    handleLocation(item) {
      pageLocationToken.set(item.id);
      this.currentLocation = item.id;
    },
    generateReport() {
      const loc = this.currentLocation;
      const apiCall = this.apiCallNames[this.currentTab];
      const reportName = this.reportNames[this.currentTab];
      this.$repositories.report[apiCall]({
        data: {
          location_id: loc,
          start_at: this.formattedStart,
          end_at: this.formattedEnd,
        },
      })
        .then((r) => {
          const { data } = r.data;
          this[reportName] = data;
        })
        .catch((e) => console.warn(e));
    },
  },
};
</script>

<style>
/* stylelint-ignore no-empty-source */
</style>
