<template>
  <VContainer
    fluid
    class="pa-0"
  >
    <VRow>
      <VCol>
        <VTabs
          v-model="currentTab"
          grow
          slider-color="white"
          color="#000"
          active-class="active-tab-background"
          class="mb-4 col-4 py-0 px-0"
        >
          <VTabsSlider color="#000" />
          <VTab
            v-for="item in tabNames"
            :key="item"
            active-class="grey lighten-2"
            class="grey lighten-4"
          >
            {{ item }}
          </VTab>
        </VTabs>
        <VTabsItems v-model="currentTab">
          <VTabItem>
            <VSimpleTable v-if="hasStaff">
              <template #default>
                <thead>
                  <tr>
                    <th
                      v-for="h in headers"
                      :key="h"
                    >
                      {{ h }}
                    </th>
                  </tr>
                </thead>
                <tbody class="table-row--low">
                  <tr
                    v-for="staff in staffs.staff"
                    :key="staff.id"
                  >
                    <td>{{ staff.last_name }} {{ staff.first_name }}</td>
                    <td
                      v-for="hh in drop(headers)"
                      :key="hh"
                    />
                  </tr>
                </tbody>
              </template>
            </VSimpleTable>
          </VTabItem>
          <VTabItem>
            <VSimpleTable v-if="hasStaff">
              <template #default>
                <thead>
                  <tr>
                    <th
                      v-for="h in headers"
                      :key="h"
                    >
                      {{ h }}
                    </th>
                  </tr>
                </thead>
                <tbody class="table-row--low">
                  <tr
                    v-for="staff in staffs.staff"
                    :key="staff.id"
                  >
                    <td class="pt-1 py-1">
                      {{ staff.last_name }} {{ staff.first_name }}
                      <p class="font-weight-light mb-0">
                        {{ staff.started }}
                      </p>
                    </td>
                    <td
                      v-for="hh in drop(headers)"
                      :key="hh"
                    />
                  </tr>
                </tbody>
              </template>
            </VSimpleTable>
          </VTabItem>
        </VTabsItems>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import drop from 'lodash/drop';

export default {
  name: 'ReportStaff',
  props: {
    staffs: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentTab: '',
      tabNames: [
        'Посещения', 'Успеваемость',
      ],
    };
  },
  computed: {
    hasStaff() {
      return !isEmpty(this.staffs);
    },
    headers() {
      if (this.hasStaff && this.staffs.tasks) {
        const headers = this.staffs.tasks.map((i) => i.name);
        return ['ФИО', ...headers, 'Итого'];
      }
      if (this.hasStaff && !this.staffs.tasks) {
        return ['ФИО'];
      }
      return [];
    },
  },
  methods: {
    drop,
  },
};
</script>

<style>
  /* stylelint-ignore no-empty-source */
</style>
