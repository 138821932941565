<template>
  <VContainer
    fluid
    class="pa-0"
  >
    <VRow>
      <VCol>
        <VSimpleTable v-if="hasEvents">
          <template #default>
            <thead>
              <tr>
                <th
                  v-for="h in headers"
                  :key="h"
                >
                  {{ h }}
                </th>
              </tr>
            </thead>
            <tbody class="table-row--low">
              <tr
                v-for="staff in events.staff"
                :key="staff.id"
              >
                <td>{{ staff.last_name }} {{ staff.first_name }}</td>
                <td
                  v-for="hh in drop(headers)"
                  :key="hh"
                />
              </tr>
            </tbody>
          </template>
        </VSimpleTable>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import drop from 'lodash/drop';

export default {
  name: 'ReportEvents',
  props: {
    events: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasEvents() {
      return !isEmpty(this.events);
    },
    headers() {
      if (this.hasEvents && this.events.non_periodic_events.events) {
        const headers = this.events.non_periodic_events.events.map((i) => i.name);
        return ['ФИО', ...headers];
      }
      if (this.hasEvents && !this.events.non_periodic_events.events) {
        return ['ФИО'];
      }
      return [];
    },
  },
  methods: {
    drop,
  },
};
</script>

<style>
  /* stylelint-ignore no-empty-source */
</style>
