<template>
  <VContainer
    fluid
    class="pa-0"
  >
    <VRow>
      <VCol>
        <h1 class="tt-text-headline-1">
          Прогресс
        </h1>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>

export default {
  name: 'ReportProgress',
};
</script>

<style>
  /* stylelint-ignore no-empty-source */
</style>
